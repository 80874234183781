<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Menu</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Nome Amigável</label>
                    <v-text-field
                        v-model="menuRequest.friendlyName" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="10"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Icone MDI</label>
                    <v-text-field
                        v-model="menuRequest.mdiIconWithoutPrefix" 
                        :rules=[validations.required]
                        single-line
                        :prefix="prefix"
                        @keyup="setMdiIcon()"
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align; align-content: center; align-items: center;"
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <v-icon large>{{menuRequest.mdiIcon}}</v-icon>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <a 
                                href="https://materialdesignicons.com/"
                                target="_blank"
                            >
                                <v-chip
                                    class="ma-2"
                                    label
                                    link
                                    outlined
                                    style="border: none; !important; font-size: 30px !important;"
                                    v-on="on"
                                >
                                    ...
                                </v-chip>
                            </a>
                        </template>
                        <span>Visite o Site - Material Designer</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <label>Ordem</label>
                    <v-text-field
                        v-model="menuRequest.order" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        v-mask="'###'"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <ActionButtons
                :request="menuRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,
            prefix: 'mdi mdi-',

            menuRequest: {
                id: 0,
                friendlyName: "",
                mdiIcon: "",
                mdiIconWithoutPrefix: "",
                order: ""
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            setMdiIcon() {
                this.menuRequest.mdiIcon = this.prefix + this.menuRequest.mdiIconWithoutPrefix;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("menuModule/GetById", this.id);

                    if (response.success) {
                        this.menuRequest = response.result;
                        this.menuRequest.mdiIconWithoutPrefix = this.menuRequest.mdiIcon.replace("mdi mdi-", "");
                    }

                }
            },

            async cancel() {
                this.$router.push({ path: "/admin/menuList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.menuRequest.id = this.id;

                    const result = await this.$store.dispatch("menuModule/CreateUpdate", this.menuRequest);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.$router.push({ path: "/admin/menuList" });
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>